import { useRouter } from "next/router";
import { useMemo } from "react";
import Avatar from "@/components/common/Avatar";

const MiniConsultantCard = () => {
  const router = useRouter();
  const isValidConsultant = useMemo(() => router.query.consultantName && router.query.consultantImage, [router.query]);

  if (!isValidConsultant) {
    return null;
  }

  const { consultantName, consultantImage, type } = router.query;

  return (
    <div className="flex items-center py-2 px-4 mb-6 bg-grey-100 rounded-lg">
      <Avatar
        author={consultantName}
        image={`${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${consultantImage}`}
        className="mr-3 w-12 h-12"
      />
      {/* TODO : I18N */}
      <div>
        {type === "follow"
          ? `Um ${consultantName} zu folgen, melde Dich bitte an.`
          : `Bitte loggen Sie sich ein, um ein Gespräch mit ${consultantName} zu beginnen.`}
      </div>
    </div>
  );
};
export default MiniConsultantCard;
