import { useState } from "react";
import PureInput from "@/components/common/form/PureInput";
import IconEyeOn from "@/common/icons/eye-on.svg";
import IconEyeOff from "@/common/icons/eye-off.svg";

const InputPassword = ({
  id,
  label,
  placeholder,
  register,
  rules,
  children,
  className,
  isNewPassword = false,
  ...inputProps
}) => {
  const [show, setShow] = useState(false);

  return (
    <div className={`flex flex-col w-full space-y-1 ${className || ""}`}>
      {label && (
        <label htmlFor={id} className="text-sm">
          {label}
        </label>
      )}
      <div className="relative w-full">
        <PureInput
          {...inputProps}
          register={register}
          rules={rules}
          id={id}
          placeholder={placeholder}
          className={children && "border-red w-full"}
          type={show ? "text" : "password"}
          autoComplete={isNewPassword ? "new-password" : "password"}
        />
        <button
          className="absolute top-1/2 right-3.5 w-4 h-4 text-grey-600 hover:text-blue -translate-y-1/2 appearance-none focus:outline-none"
          onClick={() => setShow((prev) => !prev)}
          type="button"
        >
          {show ? <IconEyeOff /> : <IconEyeOn />}
        </button>
      </div>
      {children && <span className="text-sm text-red">{children}</span>}
    </div>
  );
};

export default InputPassword;
