import clsx from "clsx";
import PureInput from "@/components/common/form/PureInput";

const Input = ({
  id,
  label,
  placeholder,
  register,
  rules,
  children,
  className,
  inputClass,
  autoComplete,
  ...inputProps
}) => (
  <div className={`flex flex-col w-full space-y-1 ${className || ""}`}>
    <label htmlFor={id} className="text-sm">
      {label}
    </label>
    <PureInput
      {...inputProps}
      register={register}
      rules={rules}
      id={id}
      placeholder={placeholder}
      className={clsx(inputClass, children && "border-red")}
      autoComplete={autoComplete}
    />
    {children && <span className="text-sm text-red">{children}</span>}
  </div>
);

export default Input;
