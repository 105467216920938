
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { NextSeo } from "next-seo";
import Layout from "@/components/core/Layout";
import Input from "@/components/common/form/Input";
import InputPassword from "@/components/common/form/InputPassword";
import MiniConsultantCard from "@/components/common/MiniConsultantCard";
import useLogin from "@/common/hook/auth/useLogin";

// consultantName
// consultantImage
// consultantTariff
const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation("auth");
  const router = useRouter();
  const [loading, error, login] = useLogin(router);

  return (
    <Layout className="flex" hasFooter={false}>
      <NextSeo title={t`auth:title.login`} />
      <div className="lg:py-28 mx-auto">
        <div className="relative lg:w-[582px]">
          <Image
            src="/login/background.png"
            alt="Background Image"
            layout="fill"
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
            className="lg:!block !hidden"
          />
          <div className="relative py-12 px-4 lg:px-12">
            <div className="mb-12 font-title text-3xl text-center">{t`common:login`}</div>
            <MiniConsultantCard />
            <form onSubmit={handleSubmit(login)} className="flex flex-col items-start w-full">
              {error && (
                <div className="flex items-center px-3 mb-4 space-x-3 w-full min-h-[3rem] bg-red-900 rounded-md">
                  <Image src="/alert-circle.svg" alt="Alert" width={20} height={20} />
                  <span className="text-sm text-red">{error}</span>
                </div>
              )}
              <Input
                label={t`common:email.label`}
                id="email"
                placeholder={t`common:email.placeholder`}
                register={register}
                rules={{ required: true, pattern: /^\S+@\S+$/i }}
                autoComplete="email"
              >
                {errors.email &&
                  (errors.email.type === "required" ? t`common:error.required` : t`common:error.valid-email`)}
              </Input>
              <InputPassword
                id="password"
                label={t`common:password.label`}
                register={register}
                rules={{ required: true, minLength: 8 }}
                placeholder={t`common:password.placeholder`}
                className="mt-6"
                autoComplete="current-password"
              >
                {errors.password &&
                  (errors.password.type === "required"
                    ? t`common:error.required`
                    : t("common:error.min-length", { length: 8 }))}
              </InputPassword>
              <button type="submit" className="self-end mt-12 w-full text-lg button hover-orange" disabled={loading}>
                {loading ? <div className="spinner" /> : t`button.login`}
              </button>
            </form>
            <div className="mt-12 text-sm text-grey-800">
              <Trans
                i18nKey="auth:redirect.reset-password"
                components={{
                  redirect: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      type="button"
                      className="font-semibold text-orange hover:text-orange-900"
                      onClick={() => router.push("/reset-password")}
                    />
                  ),
                }}
              />
            </div>
            <div className="text-sm text-grey-800">
              <Trans
                i18nKey="auth:redirect.register"
                components={{
                  redirect: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      type="button"
                      className="font-semibold text-orange hover:text-orange-900"
                      onClick={() =>
                        router.push(
                          router.query.redirectTo ? { pathname: "/register", query: router.query } : "/register"
                        )
                      }
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  